import React, { lazy, Suspense, useEffect, useState } from "react";
import { CpButton } from "canopy-styleguide!sofe";
import { getActiveTab } from "./tabs.helper.js";
import { getOldClientDrawer } from "clients-ui!sofe";
import { useFeatureBubble } from "feature-toggles!sofe";

const OldClientDrawer = lazy(getOldClientDrawer);

export function OldClientDrawerButton({
  loggedInUser,
  client,
  refetchClient,
}: {
  loggedInUser: any;
  client: any;
  refetchClient: () => void;
}) {
  const activeTab = getActiveTab(loggedInUser?.role);
  const [showClientDrawer, setShowClientDrawer] = useState(false);
  const commsTabUpdatesEnabled = useFeatureBubble({
    // if changing this featureBubble, also change it in clients-ui & communications-ui
    toggle: "toggle_comms_tab_update",
    title: "Communication Tab Update",
    expirationDate: "2025-05-01",
  });

  useEffect(() => {
    // Drawer should only be visible on comms and files tabs
    activeTab !== "files" &&
      (commsTabUpdatesEnabled ? activeTab !== "communications" : true) &&
      setShowClientDrawer(false);
  }, [activeTab]);

  return (
    <>
      {(activeTab === "files" || (commsTabUpdatesEnabled && activeTab === "communications")) && (
        <CpButton
          btnType="flat"
          icon="person-details"
          aria-label="View client details"
          className="cp-ml-16"
          onClick={() => setShowClientDrawer((prev) => !prev)}
        >
          Client Info
        </CpButton>
      )}
      <Suspense fallback={null}>
        <OldClientDrawer
          client={client}
          show={showClientDrawer}
          onClose={() => setShowClientDrawer(false)}
          refetchClient={refetchClient}
        />
      </Suspense>
    </>
  );
}
